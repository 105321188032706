import React from "react"
import { Link } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Pagina non trovata" />
    <div className="pv6 mt0 center mw9 ph3">
      <h1 className="f2 f1-ns playfair fw4 tc pv2">Pagina non trovata</h1>
      <p className="tc center mt4 frank fw4 f3 measure lh-copy">
        Per tornare alla home page cliccare sul logo o su questo link:
        <Link className="db tc ttu frank f3 fw3 mv5 br2 ph4 pv3 black" to="/">
          Home page
        </Link>
      </p>
      <p className="tc center mt4 frank fw4 f3 measure lh-copy">
        Per effetture il login:
      </p>
      <div className="flex justify-around w-50 center">
        <a
          href="https://app.time2client.com/member/session/new"
          className="frank f3 fw3 mv2 br2 bg-sec-color ph4 pv3 white hover-white hover-bg-prim-color db shadow-5"
          style={{ width: "18rem" }}
          rel="nofollow"
        >
          Entra come avvocato
        </a>
        <Link
          to="/search"
          className="pl4 frank f3 fw3 mv2 br2 bg-sec-color ph4 pv3 white hover-white hover-bg-prim-color db shadow-5"
          style={{ width: "18rem" }}
          rel="nofollow"
        >
          Entra come cliente
        </Link>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
